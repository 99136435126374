import { getElementScroll } from '../utils/elementProperties';
import { onScroll } from '../utils/scroll';
import { scrollLocked } from '../utils/scrollLock';

export function setupCtaOverlay(selector = '.cta-overlay') {
    const cta = document.querySelector(selector);

    if (cta) {

        // add load class
        setTimeout(() => {
            cta.classList.add('js-loaded');
        }, 2200);

        // scroll handling
        let scrollTimer;
        let lastScrollPosition;

        const scrollHandler = () => {
            clearTimeout(scrollTimer);
            if (!scrollLocked) {
                scrollTimer = setTimeout(() => {
                    const windowScroll = getElementScroll();

                    if (windowScroll.top > 0) {
                        cta.classList.add('js-closed');
                        lastScrollPosition = windowScroll.top;
                    } else {
                        cta.classList.remove('js-closed');
                    }
                }, 30);
            }

        };

        onScroll(scrollHandler, true);

        // click fallback for non-hover-devices
        const anyHover = window.matchMedia('(any-hover: hover)');
        if (!anyHover.matches) {
            cta.addEventListener('click', () => {
                if (cta.classList.contains('js-closed')) {
                    cta.classList.remove('js-closed');
                }
            });
        }
    }
}
