export function setupSolutions(selector = '.solutions') {
    const solutionsModules = document.querySelectorAll(selector);

    Array.from(solutionsModules).forEach(solutionsModule => {
        const slides = solutionsModule.querySelectorAll('.solutions__slide');
        const anyHover = window.matchMedia('(any-hover: hover)');

        if (anyHover.matches) {
            Array.from(slides).forEach(slide => {
                slide.addEventListener('mouseenter', () => {
                    solutionMouseEnter(slide, slides);
                });
                slide.addEventListener('mouseleave', () => {
                    solutionMouseLeave(slide, slides);
                });
            });
        }
    });
}

function solutionMouseEnter(slide, allSlides) {
    const slideTextWrap = slide.querySelector('.solutions__slide-text-wrap');
    const slideImageWrap = slide.querySelector('.solutions__slide-image-wrap');

    if (slideImageWrap) {
        const imageWrapWidth = slideImageWrap.getBoundingClientRect().width;
        const allSlidesExceptCurrent = Array.from(allSlides).filter(x => x != slide);

        // get total width of slides
        let slidesTotalWidth = 0;
        Array.from(allSlides).forEach(slide => {
            slidesTotalWidth += slide.scrollWidth;
        });

        // set current width
        slide.style.width = `${imageWrapWidth}px`;

        // set a width for the rest
        const otherSlidesWidth = (slidesTotalWidth - imageWrapWidth) / allSlidesExceptCurrent.length;
        Array.from(allSlidesExceptCurrent).forEach(slide => {
            slide.style.width = `${otherSlidesWidth}px`;
        });
    }

    // text height
    if (slideTextWrap) {
        slideTextWrap.style.height = `${slideTextWrap.scrollHeight}px`;
    }
}

function solutionMouseLeave(slide, allSlides) {
    const slideTextWrap = slide.querySelector('.solutions__slide-text-wrap');

    // clear slides width
    Array.from(allSlides).forEach(slide => {
        slide.style.width = null;
    });

    // clear text height
    if (slideTextWrap) {
        slideTextWrap.style.height = '0';
    }
}
