function imageLoaded(image) {
    if (image) {
        const pictureElm = image.parentNode.nodeName.toLowerCase() == 'picture' ? image.parentNode : null;

        image.setAttribute('data-image-loaded', 'true');

        if (pictureElm) {
            pictureElm.setAttribute('data-image-loaded', 'true');
        }
    }
}

export function setupImagesLoaded(selector = 'img[loading="lazy"]') {
    const images = document.querySelectorAll(selector);

    images.forEach(image => {
        if (image.complete) {
            imageLoaded(image);
        } else {
            image.addEventListener('load', () => {
                imageLoaded(image);
            });
        }
    });
}
