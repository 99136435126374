export let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

let ticking = false;
const scrollFunctions = [];

function animate() {
    scrollFunctions.forEach(funcRef => funcRef());

    ticking = false;
}

function requestTick() {
    if (!ticking) {
        requestAnimationFrame(animate);
        ticking = true;
    }
}

function scrollHandler() {
    scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    requestTick();
}

/**
 * Adds a function to a function array, executed on a single scroll-event set on window.
 * This avoids the memory load and possible hickups of setting multiple eventlisteners for the same event.
 * Also this optimizes rendering by utilising the requestAnimationFrame for these scroll-events.
 *
 * @param {Function} handler - function to be called on scroll
 * @param {boolean} triggerNow - Should the function be called at once
 */
export function onScroll(handler, triggerNow = false) {
    // if first call: setup eventlistener on window
    !scrollFunctions.length ? initScroll() : null;

    // Trigger function
    triggerNow ? handler() : null;

    scrollFunctions.push(handler);
}

export function initScroll() {
    window.addEventListener('scroll', scrollHandler);
}

/**
 * Scrolls the viewport to an hash-id
 * if found in querystring
 */
export function scrollToUrlHash() {
    if (window.location.hash) {
        const element = document.getElementById(window.location.hash.replace('#', ''));
        const topPos = element?.getBoundingClientRect().top + window.pageYOffset;

        if (element) {
            window.scrollTo(element, topPos);
        }
    }
}
