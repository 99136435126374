import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { breakpoints, currentWindowWidth } from '../utils/windowResize';

export let nav;

export function toggleMenuOpen() {
    if (nav.classList.contains('navigation--open')) {
        disableScrollLock();
        nav.classList.remove('navigation--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('navigation--going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('navigation--open');
    }
}

export function setupNavigation(selector = '.navigation', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.navigation__btn');
        const flyOutLinks = nav.querySelectorAll('.navigation__item--fly-out > .navigation__link');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'navigation--sticky', 30, 'navigation--going-up');
        }

        Array.from(flyOutLinks).forEach(flyOutLink => {
            flyOutLink.addEventListener('click', e => {
                e.preventDefault();

                const navItem = e.target.parentNode;
                if (navItem) {
                    const flyOutElm = navItem.querySelector('.navigation__fly-out');
                    if (flyOutElm) {
                        if (!navItem.classList.contains('navigation__item--fly-out-closed')) {
                            closeFlyOut(navItem, flyOutLink, flyOutElm);
                        } else {
                            openFlyOut(navItem, flyOutLink, flyOutElm);
                        }
                    }
                }
            });
        });
    }
}

function openFlyOut(navItem, link, flyOutElm) {
    if (currentWindowWidth < (breakpoints['lg'] - .02)) {
        flyOutElm.style.height = `${flyOutElm.scrollHeight}px`;
        flyOutElm.addEventListener('transitionend', function(e) {
            if (e.propertyName === 'height') {
                flyOutElm.style.height = null;
            }
        });
    } else {
        enableScrollLock();
    }

    navItem.classList.remove('navigation__item--fly-out-closed');
    flyOutElm.setAttribute('aria-hidden', false);
    link.setAttribute('aria-expanded', true);
}

function closeFlyOut(navItem, link, flyOutElm) {
    if (currentWindowWidth < (breakpoints['lg'] - .02)) {
        flyOutElm.style.height = `${flyOutElm.scrollHeight}px`;
        setTimeout(() => {
            flyOutElm.style.height = '0';
        }, 0);
    } else {
        disableScrollLock();
    }

    navItem.classList.add('navigation__item--fly-out-closed');
    flyOutElm.setAttribute('aria-hidden', true);
    link.setAttribute('aria-expanded', false);
}
