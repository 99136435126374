
/**
 * Simply adds a class to target field if it has a value and removes it if not.
 *
 * @param {Object || HTMLElement} e - Event or field element
 */
function checkFieldValue(e) {
    const field = e.currentTarget || e;
    const fieldWrap = field.closest('.form__field-wrap');

    if (fieldWrap) {
        field.value ? fieldWrap.classList.add('js-has-value') : fieldWrap.classList.remove('js-has-value');
    }
}

/**
 * Sets up 'change'-eventhandler as checkValidation on given fields
 *
 * @param {HTMLElement} form - Form container with fields to validate
 */

export function watchFormFields(selector = '[data-action*="form-watch-fields"]') {
    const form = document.body.querySelectorAll(selector);

    if (form) {
        for (let f = 0; f < form.length; f++) {
            const fields = form[f].querySelectorAll('input, select, textarea');

            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];

                //Check for prefilled fields
                checkFieldValue(field);
                if (field.type === 'select-one' || field.type === 'select-multiple') {
                    field.addEventListener('change', checkFieldValue);
                } else {
                    field.addEventListener('keyup', checkFieldValue);
                }
            }
        }
    }
}

function postFormToAc(e) {
    e.preventDefault();

    const form = this.closest('[data-form]');
    const formErrors = form.querySelector('.form__errors');
    const formElements = form.querySelectorAll('input, textarea, select');
    const formEmail = form.querySelector('[name=\'email\']')?.value;

    const contactObj = {
        fieldValues: []
    };

    const hiddenObj = {
        actionData: form.querySelector('[name=\'actiondata\']')?.value,
        thanks: form.querySelector('[name=\'thanks\']')?.value,
        submit: form.querySelector('[name=\'submit\']')?.value,
        submitDataUrl: form.querySelector('[name=\'submitdata-url\']')?.value,
        name: form.querySelector('[name=\'name\']')?.value
    };

    if (formElements) {
        formElements.forEach(el => {

            if (el.parentNode.classList.contains('form__data')) {
                return;
            }

            if (el.classList.contains('custom-field')) {

                if (el.type && el.type === 'radio') {

                    if (el.checked) {
                        contactObj.fieldValues.push({ field: el.name, value: el.value });
                    }

                } else if (el.type && el.type === 'checkbox') {

                    if (el.checked && !contactObj.fieldValues.some(e => e.field === el.name)) {
                        const possibleSiblings = form.querySelectorAll(`[name="${el.name}"]:checked`);
                        const selectedCheckboxes = Array.from(possibleSiblings).map(({ value }) => value);
                        const values = selectedCheckboxes.join('||');
                        contactObj.fieldValues.push({ field: el.name, value: `||${values}||` });
                    }

                } else if (el.tagName === 'SELECT' && el.hasAttribute('multiple')) {

                    if (el.selectedIndex !== -1) {
                        const selectedOptions = Array.from(el.selectedOptions).map(({ value }) => value);
                        const values = selectedOptions.join('||');
                        contactObj.fieldValues.push({ field: el.name, value: `||${values}||` });
                    }

                } else {

                    if (el.value) {
                        contactObj.fieldValues.push({ field: el.name, value: el.value });
                    }
                }

            } else {

                if (!el.value) {
                    return;
                }

                if (el.name === 'fullname') {
                    const fullName = el.value;
                    let firstName = fullName;
                    let lastName = '';

                    if (fullName.indexOf(' ') >= 0) {
                        firstName = fullName.substr(0,fullName.indexOf(' '));
                        lastName = fullName.substr(fullName.indexOf(' ') + 1);
                    }

                    contactObj['firstname'] = firstName;
                    contactObj['lastname'] = lastName;

                } else {

                    contactObj[el.name] = el.value;
                }
            }
        });
    }

    const data = JSON.stringify({
        contact: contactObj,
        actiondata: JSON.parse(hiddenObj.actionData)
    });

    form.classList.add('form--loading');
    formErrors.innerHTML = '';

    const xhttp = new XMLHttpRequest();
    xhttp.open('POST', '/umbraco/surface/AC/AddACContact', true);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4) {
            window.dataLayer = window.dataLayer || [];

            if (this.status == 200) {
                const response = this.responseText;
                const jsonResponse = response ? JSON.parse(response) : response;

                if (!jsonResponse.errors) {

                    /* Tracking */
                    window.dataLayer.push({
                        'event': 'Form Submission',
                        'formName': hiddenObj.name,
                        'email': formEmail,
                        'status': 'success'
                    });

                    if (hiddenObj.submit === 'open-url' && hiddenObj.submitDataUrl) {
                        window.location.href = hiddenObj.submitDataUrl;
                    } else {
                        form.innerHTML = `<p class="form__text">${hiddenObj.thanks}</p>`;
                    }

                } else {

                    jsonResponse.errors.forEach(error => {
                        const li = document.createElement('li');
                        li.classList.add('form__errors-item');
                        li.innerHTML = error.title;
                        formErrors.appendChild(li);
                    });
                }

            } else {
                const response = `Error: ${this.status} - ${this.statusText}`;
                console.log('error', response);
            }

            form.classList.remove('form--loading');
        }
    };

    xhttp.send(data);
}

function getFormFromAC(form) {
    const formId = form.getAttribute('data-form'),
        culture = document.documentElement.lang;

    /* Prepare parameters */
    let url = '/umbraco/surface/AC/GetACForm';
    let params = `?culture=${culture}`;

    if (formId) {
        params += `&formId=${formId}`;
    }

    if (params) {
        url += `${params}`;
    }

    const xhttp = new XMLHttpRequest();
    xhttp.open('GET', url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4) {
            if (this.status == 200) {
                const response = this.responseText;
                form.insertAdjacentHTML('beforeend', response);
                const formElement = form.querySelector('form');
                if (formElement) {
                    formElement.addEventListener('submit', postFormToAc);
                    watchFormFields();
                }
            } else {
                const response = `Error: ${this.status} - ${this.statusText}`;
                form.insertAdjacentHTML('beforeend', response);
            }
            form.classList.remove('form--initialization');
        }
    };

    xhttp.send();
}

export function setupForms(selector = '[data-form]') {
    const forms = document.body.querySelectorAll(selector);

    if (forms) {
        forms.forEach(form => {
            getFormFromAC(form);
        });
    }
}
