export let searchModal,
    searchModalForm,
    searchModalInput,
    searchInput,
    searchToggleBtn;

export function showSearchOverlay() {
    document.body.classList.add('search--open');
}

export function hideSearchOverlay() {
    document.body.classList.remove('search--open');
    searchModalInput.blur();
    searchModalInput.value = '';
}

export function setupSearch() {

    searchModal = document.body.querySelector('.search__modal');
    searchModalForm = document.getElementById('search-modal-form');
    searchModalInput = document.getElementById('search-modal-input');
    searchInput = document.body.querySelector('.search__input');
    searchToggleBtn = document.body.querySelector('.navigation__toggle-search');

    if (searchModal && searchModalForm && searchModalInput && searchToggleBtn) {

        searchToggleBtn.addEventListener('click', showSearchOverlay);
        searchModal.addEventListener('click', function(e) {
            if (!searchModalForm.contains(e.target)) {
                hideSearchOverlay();
            }
        });

        document.addEventListener('keyup', function (e) {
            // escape key.
            if (e.keyCode == 27) {
                hideSearchOverlay();
            }
        });
    }

    if (searchInput) {
        searchInput.addEventListener('focus', () => {
            // Set the cursor to end of the input
            const val = searchInput.value;
            searchInput.value = '';
            searchInput.value = val;
        });
    }
}
