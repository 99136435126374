/**
 * Utilities for checking properties and states of elements.
 */

/**
 * Check if an element is empty.
 *
 * @param {Node} element - Check if this element is empty.
 * @param {boolean} [strict=true] - Set this to **false** to ignore nodes with whitespace.
 * @returns {boolean} **True** if the element is empty.
 */
export function elementIsEmpty(element, strict = true) {
    return strict ? !element.childNodes.length : !element.innerHTML.trim().length;
}

/**
 * Check if an element is hidden in the DOM with `display: none;`
 *
 * @param {HTMLElement} element - The element to check.
 * @returns {boolean} **True** if element is hidden, otherwise **false**.
 */
export function elementIsHidden(element) {
    return element.offsetParent === null;
}

/**
 * Check if an element is in the viewport
 *
 * @param {HTMLElement} elem - The element to check
 */
export function isVisible(elem) {
    return !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
}

/**
 * Find out whether or not the given argument is an element that would react somewhat normally to DOM-manipulations.
 *
 * @param {*} element - The element to check.
 * @returns {boolean} `true` if the given argument is an element (or document, or window), and `false` otherwise.
 */
export function isElement(element) {
    return element instanceof Element || element instanceof Document || element instanceof Window;
}

/**
 * Return the position of an element
 *
 * @param {Element|String} element - The HTML element to work with or its ID
 * @param {Element|String|Window} [relativeTo=window] - The HTML element to return the position relative to or its ID
 * @returns {{top: Number, left: Number}} An object with top and left positions in pixels
 *
 *
 * @example <caption>Basic usage:</caption>
 * import { getElementPosition } from './utils/dom/elementProperties';
 *
 * const element = document.querySelector('.anElement');
 * getElementPosition(element);
 *
 *
 * @example <caption>Perform a search for an element with an ID equal to the string, i.e. 'elementId', and get the position of that:</caption>
 * import { getElementPosition } from './utils/dom/elementProperties';
 *
 * getElementPosition('elementId');
 */
export function getElementPosition(element, relativeTo = window) {
    const useElement = typeof element === 'string' ? document.getElementById(element) : element;

    // Throw error if element wasn't found
    if (!useElement) {
        throw 'getElementPosition did not find an element.';
    }

    const useRelativeTo = typeof relativeTo === 'string' ? document.getElementById(relativeTo) : relativeTo;

    // Throw error if relative element wasn't found
    if (!useRelativeTo) {
        throw 'getElementPosition did not find an element to show the position relative to.';
    }

    if (relativeTo === window) {
        // Return position relative to window
        const rect = useElement.getBoundingClientRect();
        return {
            top: rect.top + (window.pageYOffset || document.documentElement.scrollTop),
            left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft)
        };
    } else {
        // Return position relative to declared element
        return {
            top: useElement.offsetTop - relativeTo.offsetTop,
            left: useElement.offsetLeft - relativeTo.offsetLeft
        };
    }
}

/**
 * Get the current scroll values of the given element (or window). Will return an object containing
 * "left" and "top" properties, which are set to the scroll values, or false if no compatible element
 * was given.
 *
 * @param {Element|Window} [element=window]
 * @returns {{ left: number, top: number } | boolean}
 */
export function getElementScroll(element = window) {
    if (isElement(element)) {
        if (element instanceof Window) {
            return {
                left: element.pageXOffset || document.documentElement.scrollLeft,
                top: element.pageYOffset || document.documentElement.scrollTop
            };
        } else {
            return {
                left: element.scrollX || element.scrollLeft,
                top: element.scrollY || element.scrollTop
            };
        }
    } else {
        console.warn('Can\'t get scroll-position or given argument type.');
        return false;
    }
}

/**
 * Get both width and height of element
 *
 * @param {Element} element - The HTML element to work with
 * @param {Object} [options={}] - Object of options
 * @param {boolean} [options.includePadding=false] - Get size including padding (defaults to true)
 * @param {boolean} [options.includeBorder=false] - Get size including border (defaults to true)
 * @param {boolean} [options.includeMargin=true] - Get size including margin (defaults to false)
 * @param {null|':before'|':after'} [options.pseudoElement=null] - Get size of pseudo element ':before' or ':after'
 * @returns {{width: number, height: number}} An object with the width and height as numbers
 */
export function getElementSize(element, options = {}) {
    // Get styles
    const elementStyle = window.getComputedStyle(element, options.pseudoElement);

    return {
        width: getElementWidth(element, options, elementStyle),
        height: getElementHeight(element, options, elementStyle)
    };
}

/**
 * Get width of element
 *
 * @param {Element} element - The HTML element to work with
 * @param {Object} [options={}] - Object of options
 * @param {boolean} [options.includeMargin=false] - Get width including margin (defaults to false)
 * @param {boolean} [options.includeBorder=true] - Get width including border (defaults to true)
 * @param {boolean} [options.includePadding=true] - Get width including padding (defaults to true)
 * @param {null|':before'|':after'} [options.pseudoElement=null] - Get width of pseudo element ':before' or ':after'
 * @param {CSSStyleDeclaration} [elementStyle] - Style declaration of element (in case you already have called .getComputedStyle(), pass its returned value here)
 * @returns {number} The width as a number
 */
export function getElementWidth(element, options = {}, elementStyle = null) {
    // Keep supplied values or set to defaults
    options.includeMargin = options.includeMargin === true;
    options.includeBorder = options.includeBorder !== false;
    options.includePadding = options.includePadding !== false;

    // Get styles
    const style = elementStyle || window.getComputedStyle(element, options.pseudoElement);

    // Get width including border and padding
    let width = element.offsetWidth;

    // Calculate width with margin
    if (options.includeMargin) {
        width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    }

    // Calculate width without border
    if (!options.includeBorder) {
        width -= parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
    }

    // Calculate width without padding
    if (!options.includePadding) {
        width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    }

    return width;
}

/**
 * Get height of element
 *
 * @param {Element} element - The HTML element to work with
 * @param {Object} [options={}] - Object of options
 * @param {boolean} [options.includeMargin=false] - Get height including margin (defaults to false)
 * @param {boolean} [options.includeBorder=true] - Get height including border (defaults to true)
 * @param {boolean} [options.includePadding=true] - Get height including padding (defaults to true)
 * @param {null|':before'|':after'} [options.pseudoElement=null] - Get height of pseudo element ':before' or ':after'
 * @param {CSSStyleDeclaration} [elementStyle] - Style declaration of element (in case you already have called .getComputedStyle(), pass its returned value here)
 * @returns {number} The height as a number
 */
export function getElementHeight(element, options = {}, elementStyle = null) {
    // Keep supplied values or set to defaults
    options.includeMargin = options.includeMargin === true;
    options.includeBorder = options.includeBorder !== false;
    options.includePadding = options.includePadding !== false;

    // Get styles
    const style = elementStyle || window.getComputedStyle(element, options.pseudoElement);

    // Get height including border and padding
    let height = element.offsetHeight;

    // Calculate height with margin
    if (options.includeMargin) {
        height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    }

    // Calculate height without border
    if (!options.includeBorder) {
        height -= parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
    }

    // Calculate height without padding
    if (!options.includePadding) {
        height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
    }

    return height;
}
