export function setupEmployees(selector = '.employees') {
    const employeesModules = document.querySelectorAll(selector);

    Array.from(employeesModules).forEach(employeesModule => {
        const employees = employeesModule.querySelectorAll('.employees__item-aspect-ratio');
        const anyHover = window.matchMedia('(any-hover: hover)');

        Array.from(employees).forEach(employee => {
            const readMoreLink = employee.querySelector('.employees__item-read-more');

            if (anyHover.matches) {
                employee.addEventListener('mouseenter', () => {
                    employeeMouseEnter(employee);
                });
                employee.addEventListener('mouseleave', () => {
                    employeeMouseLeave(employee);
                });
            }

            if (readMoreLink) {
                readMoreLink.addEventListener('click', () => {
                    employeeMouseEnter(employee, true);
                    readMoreLink.classList.add('employees__item-read-more--hide');
                });
            }
        });
    });
}

function employeeMouseEnter(employee, resetHeightOnTransitionEnd) {
    const employeeTextWrap = employee.querySelector('.employees__item-text-wrap');

    // text height
    if (employeeTextWrap) {
        employeeTextWrap.style.height = `${employeeTextWrap.scrollHeight}px`;

        if (resetHeightOnTransitionEnd) {
            employeeTextWrap.addEventListener('transitionend', function(e) {
                if (e.propertyName === 'height') {
                    employeeTextWrap.style.height = 'auto';
                }
            });
        }
    }
}

function employeeMouseLeave(employee) {
    const employeeTextWrap = employee.querySelector('.employees__item-text-wrap');

    // clear text height
    if (employeeTextWrap) {
        employeeTextWrap.style.height = '0';
    }
}
