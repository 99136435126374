let YT;
let videoModulesArray = [];
const queue = {
    content: null,
    push(fn) {
        this.content = fn;
    },
    pop() {
        this.content.call();
        this.content = null;
    }
};

function loadYouTubeApi() {
    const tag = document.createElement('script');
    tag.src = `${window.location.protocol}//www.youtube.com/iframe_api`;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onPlayerReady(event) {
    // Remove "video--no-youtube-api" class on the current video module
    const iframe = event.target.getIframe();
    if (iframe) {
        const videoModule = iframe.closest('.video');
        if (videoModule) {
            videoModule.classList.remove('video--no-youtube-api');
        }
    }

    // Call the function in the queue
    if (queue.content) {
        queue.pop();
    }
}

function isPlayerReady(player) {
    return player && typeof player.playVideo === 'function';
}

function playVideo(player) {
    // If the player is not ready, queue player.playVideo() and invoke it when the player is ready
    isPlayerReady(player) ? player.playVideo() : queue.push(() => {
        player.playVideo();
    });
}

window.onYouTubeIframeAPIReady = function() {
    // Define the global "YT" variable
    YT = window.YT;

    // Create Youtube players
    Array.from(videoModulesArray).forEach(videoModule => {
        const iframe = videoModule.querySelector('.video__iframe');
        if (iframe) {
            const player = new YT.Player(iframe, {
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': event => {
                        if (!videoModule.classList.contains('video--loading') && event.data === YT.PlayerState.UNSTARTED) {
                            videoModule.classList.add('video--loading');
                        }

                        if (event.data === YT.PlayerState.PLAYING) {
                            videoModule.classList.remove('video--loading');
                            videoModule.classList.add('video--playing');
                        }

                        if (event.data === YT.PlayerState.ENDED) {
                            videoModule.classList.remove('video--playing');
                        }
                    }
                }
            });

            const playTriggers = videoModule.querySelectorAll('.video__play, .video__image-wrap');
            Array.from(playTriggers).forEach(trigger => {
                trigger.addEventListener('click', () => {
                    videoModule.classList.add('video--loading');
                    playVideo(player);
                });
            });
        }
    });
};

export function setupVideoModules(selector = '.video') {
    const videoModules = document.querySelectorAll(selector);
    if (videoModules.length > 0) {

        // Set the global 'videoModulesArray' variable
        videoModulesArray = videoModules;

        // Load Youtube API if it doesn't exist already
        if (!YT) {
            loadYouTubeApi();
        }
    }
}