import { swipers, swiperClasses } from '../components/swiper';
import { breakpoints as baselineBreakpoints, currentWindowWidth } from '../utils/windowResize';

let virtualTranslate = false;
let virtualTranslateSet = false;

export function setupIndustries(selector = '.industries') {
    const industries = document.querySelectorAll(selector);

    industries.forEach(industryModule => {
        const swiperContainer = industryModule.querySelector('.industries__swiper');
        if (swiperContainer) {
            const swiper = swipers.filter(x => x.el == swiperContainer)[0];
            if (swiper) {

                // set swiper settings
                swiper.params.slideToClickedSlide = true;
                swiper.update();
                swiperSetupResponsiveSettings(swiper);

                swiper.on('resize', e => {
                    swiperSetupResponsiveSettings(swiper);
                });

                // custom slide change
                swiper.on('slideChangeTransitionStart', e => {
                    const newSlideIndex = e.slides.findIndex(x => x.classList.contains(swiperClasses.slideActiveClass));
                    if (newSlideIndex >= 0) {
                        const newSlide = e.slides[newSlideIndex];

                        //update pagination color
                        setPaginationColor(newSlide, swiper);

                        // activate slide if screen size is larger than 'lg'
                        if (currentWindowWidth >= baselineBreakpoints['lg']) {
                            activateSlide(newSlideIndex, e.slides, swiper);
                        }
                    }
                });

                swiper.on('transitionEnd', e => {

                    // remove transform etc. when virtualTranslate is true
                    if (virtualTranslate) {
                        swiper.wrapperEl.removeAttribute('style');
                    }
                });

                // add eventlisteners for each slide
                swiper.slides.forEach((slide, a) => {

                    slide.addEventListener('click', () => {
                        // check if the slide isn't the active slide
                        if (a != swiper.activeIndex) {

                            // activate slide
                            activateSlide(a, swiper.slides, swiper);

                            //update pagination color
                            setPaginationColor(slide, swiper);
                        }
                    });

                    slide.addEventListener('mouseenter', () => {
                        // check if the slide isn't the active slide
                        if (a != swiper.activeIndex) {

                            swiper.slides.forEach((slideItem, b) => {
                                // move previous slides left
                                if (a > swiper.activeIndex) {
                                    if (b < a) {
                                        slideItem.classList.add('swiper-slide-offset-transition');
                                        slideItem.classList.add('swiper-slide-offset-left');
                                    }
                                }
                                // move current slide and next slides right
                                else {
                                    if (b >= a) {
                                        slideItem.classList.add('swiper-slide-offset-transition');
                                        slideItem.classList.add('swiper-slide-offset-right');
                                    }
                                }
                            });
                        }
                    });

                    slide.addEventListener('mouseleave', () => {
                        // check if the slide isn't the active slide
                        if (a != swiper.activeIndex) {

                            // remove offset classes
                            swiper.slides.forEach((slideItem, b) => {
                                slideItem.classList.remove('swiper-slide-offset-left');
                                slideItem.classList.remove('swiper-slide-offset-right');
                            });
                        }
                    });
                });
            }
        }
    });
}

function setPaginationColor(newSlide, swiper) {
    const newSlideColor = newSlide.getAttribute('data-slide-color');
    if (newSlideColor) {
        swiper.pagination.el.setAttribute('data-color', newSlideColor);
    }
}

function activateSlide(slideIndex, slides, swiper) {

    // set class for slides before this
    slides.forEach((slide, i) => {
        slide.classList.remove('swiper-slide-offset-transition');
        slide.classList.remove('swiper-slide-offset-left');
        slide.classList.remove('swiper-slide-offset-right');

        if (i < slideIndex) {
            slide.classList.add('swiper-slide-before-active');
        } else {
            slide.classList.remove('swiper-slide-before-active');
        }
    });

    // set active state
    swiper.el.setAttribute('data-swiper-active-index', slideIndex);
}

function swiperSetupResponsiveSettings(swiper) {
    const virtualTranslateMqMin = window.matchMedia(`(min-width: ${baselineBreakpoints['lg']}px)`);
    const virtualTranslateMqMax = window.matchMedia(`(max-width: ${baselineBreakpoints['lg'] - .02}px)`);

    // if resize passes 'lg' breakpoint on the way up
    if ((!virtualTranslateSet || !virtualTranslate) && virtualTranslateMqMin.matches) {
        swiper.params.virtualTranslate = true;
        swiper.allowTouchMove = false;
        swiper.wrapperEl.removeAttribute('style');

        // activate slide if not the first
        if (swiper.activeIndex > 0) {
            activateSlide(swiper.activeIndex, swiper.slides, swiper);
        }

        virtualTranslate = true;
        virtualTranslateSet = true;
    }
    // if resize passes 'lg' breakpoint on the way down
    else if ((!virtualTranslateSet || virtualTranslate) && virtualTranslateMqMax.matches) {
        swiper.params.virtualTranslate = false;
        swiper.allowTouchMove = true;
        virtualTranslate = false;
        virtualTranslateSet = true;
    }
}
