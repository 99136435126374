import { scrollToUrlHash } from '../utils/scroll';

function getUrlParameterByName(name) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(name);
}

function filterClick(filter, filterValue, filters, filterItems, moduleId) {
    const visibleFilterItems = [];
    const cleanFilterValue = filterValue.trim().toLowerCase();

    // reset filter if already selected
    const isSelected = filter.hasAttribute('aria-selected') ? filter.getAttribute('aria-selected').toLowerCase() === 'true' : false;
    if (isSelected) {
        // eslint-disable-next-line no-param-reassign
        filterValue = '*';
    }

    // toggle aria-selected for filters
    for (let i = 0; i < filters.length; i++) {
        const filterItem = filters[i];

        if (filterItem == filter && !isSelected) {
            filterItem.setAttribute('aria-selected', true);
        } else {
            filterItem.setAttribute('aria-selected', false);
        }
    }

    // show/hide filter items
    for (let i = 0; i < filterItems.length; i++) {
        const item = filterItems[i];
        let itemFilterValues = item.getAttribute('data-filter-value').split(',');
        itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

        if (filterValue !== '*' && itemFilterValues.indexOf(cleanFilterValue) === -1) {
            item.setAttribute('aria-hidden', true);
        } else {
            item.setAttribute('aria-hidden', false);
            visibleFilterItems.push(item);
        }

        // remove even/odd classes
        item.classList.remove('even');
        item.classList.remove('odd');
    }

    // add even/odd classes
    for (let i = 0; i < visibleFilterItems.length; i++) {
        const item = visibleFilterItems[i];

        if (i % 2 == 0) {
            item.classList.add('even');
        } else {
            item.classList.add('odd');
        }
    }

    //update url
    let newUrl = `${location.protocol}//${location.host}${location.pathname}?filter=${filterValue}${location.hash ? location.hash : moduleId}`;
    if (isSelected) {
        newUrl = `${location.protocol}//${location.host}${location.pathname}`;
    }

    const nextTitle = document.title;
    const nextState = { additionalInformation: 'Updated the URL with JS' };
    window.history.replaceState(nextState, nextTitle, newUrl);
}

export function setupFilters(selector = '[data-action*="filter"]') {
    const filterContainers = document.body.querySelectorAll(selector);
    const filterUrlParam = getUrlParameterByName('filter');

    filterContainers.forEach(filterContainer => {
        const filters = filterContainer.querySelectorAll('[data-filter]');
        const targetContainer = document.querySelector(filterContainer.getAttribute('data-target-container'));
        const module = filterContainer.closest('.module');
        const moduleId = module ? `#${module.id}` : '';

        if (targetContainer) {
            const filterItems = targetContainer.querySelectorAll('[data-filter-value]');

            filters.forEach(filter => {
                const filterValue = filter.getAttribute('data-filter');

                // filter by URL
                if (filterUrlParam) {
                    const cleanFilterUrlParam = filterUrlParam.toLowerCase();

                    if (cleanFilterUrlParam.includes(filter.getAttribute('data-filter'))) {
                        setTimeout(() => {
                            filter.click();
                            scrollToUrlHash();
                        }, 100);
                    }
                }

                // filter by click
                filter.addEventListener('click', () => {
                    filterClick(filter, filterValue, filters, filterItems, moduleId);
                });
            });
        }
    });
}
