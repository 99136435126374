import Player from '@vimeo/player';

function setHeaderVideoSize(header, video) {
    const headerWidth = header.clientWidth;
    const headerHeight = header.clientHeight;
    const videoHeight = headerWidth * .5625;

    video.style.width = null;
    video.style.height = `${Math.ceil(videoHeight)}px`;

    if (headerHeight > videoHeight) {
        const videoWidth = headerHeight * 1.777777777777778;

        video.style.height = null;
        video.style.width = `${Math.ceil(videoWidth)}px`;
    }
}

export function calculateHeaderVideoSize(selector = '.header') {
    const header = document.querySelector(selector);

    if (header) {
        const headerVideo = header.querySelector('.header__video');

        if (headerVideo) {
            const headerHeight = header.clientHeight;

            setHeaderVideoSize(header, headerVideo);

            document.onreadystatechange = () => {
                if (document.readyState === 'complete' && header.clientHeight !== headerHeight) {
                    setHeaderVideoSize(header, headerVideo);
                }
            };
        }
    }
}

export function setupHeaderVideo(selector = '.header') {
    const header = document.querySelector(selector);

    if (header) {
        const headerVideo = header.querySelector('.header__video');

        if (headerVideo) {
            const player = new Player(headerVideo);
            player.on('play', function() {
                header.classList.add('video--playing');
            });
        }
    }
}
