import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Pagination, Navigation]);

export const swipers = [];

export const swiperClasses = {
    slideActiveClass: 'swiper-slide-active',
    slidePrevClass: 'swiper-slide-prev',
    slideNextClass: 'swiper-slide-next'
};

export function setupSwipers() {
    const swiperContainers = document.querySelectorAll('[data-swiper]');

    swiperContainers.forEach(swiperContainer => {
        let edgeRelease = false;
        if (swiperContainer.hasAttribute('data-swiper-edge-release')) {
            edgeRelease = swiperContainer.getAttribute('data-swiper-edge-release').toLowerCase() === 'true';
        }

        const swiper = new Swiper(swiperContainer, {
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slideActiveClass: swiperClasses.slideActiveClass,
            slidePrevClass: swiperClasses.slidePrevClass,
            slideNextClass: swiperClasses.slideNextClass,
            slidesPerView: 'auto', // Slide sizes are controlled with css - not here
            speed: 500,
            slideToClickedSlide: false,
            touchReleaseOnEdges: edgeRelease
        });

        swipers.push(swiper);

        // settings when swiper can't slide
        if (!edgeRelease && !swiper.isEnd) {
            swiperContainer.classList.add('swiper-pagination-showed');
            swiper.params.touchReleaseOnEdges = false;
            swiper.update();
        }
    });
}
