import { onWindowResize } from './utils/windowResize';
import { onReady } from './utils/onReady';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { scrollToUrlHash } from './utils/scroll';
import { setupIntersect } from './components/intersect';
import { setupNavigation } from './layout/navigation';
import { calculateHeaderVideoSize, setupHeaderVideo } from './layout/header';
import { setupSearch } from './layout/search';
import { setupLanguageSelector } from './components/language-selector';
import { setupAccordions } from './components/accordions';
import { setupTabs } from './components/tabs';
import { setupVideoModules } from './modules/video';
import { setupSwipers } from './components/swiper';
import { setupSolutions } from './modules/solutions';
import { setupEmployees } from './modules/employees';
import { setupCtaOverlay } from './layout/cta-overlay';
import { setupForms, watchFormFields } from './components/form';
import { setupIndustries } from './modules/industries';
import { setupFilters } from './components/filter';
import { setupImagesLoaded } from './components/images';

function init() {
    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        initVhUnitOverwrite(calculateHeaderVideoSize);
        scrollToUrlHash();
        setupIntersect();
        setupNavigation();
        setupHeaderVideo();
        setupSearch();
        setupLanguageSelector();
        setupAccordions();
        setupTabs();
        setupVideoModules();
        setupSwipers();
        setupSolutions();
        setupEmployees();
        setupCtaOverlay();
        setupForms();
        //watchFormFields(); /* Only necessary for prototyping */
        setupIndustries();
        setupFilters();
        setupImagesLoaded();
    });

    onWindowResize(() => {
        calculateHeaderVideoSize();
    });
}

init();
