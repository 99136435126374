/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */

// Used keycodes
const keycode = {
    arrowRight: 39,
    arrowLeft: 37,
    home: 36,
    end: 35
};

const tabSelector = '[data-action="tab"]';

function openTab(e) {
    const tab = e.target ? e.target : e;

    if (!(e.ctrlKey || e.metaKey || e.shiftKey) && e.target) {
        e.preventDefault();
    }

    const tabContent = document.getElementById(tab.getAttribute('aria-controls').replace('#', ''));

    if (tabContent) {
        const activeTab = tab.parentNode.parentNode.querySelector('[aria-selected="true"]');

        if (activeTab) {
            activeTab.setAttribute('aria-selected', false);
            activeTab.setAttribute('tabindex', -1);

            const activeTabContent = document.getElementById(activeTab.getAttribute('aria-controls').replace('#', ''));
            if (activeTabContent) {
                activeTabContent.setAttribute('aria-hidden', true);
            }
        }

        tab.removeAttribute('tabindex');
        tab.setAttribute('aria-selected', true);
        tab.focus();
        tabContent.setAttribute('aria-hidden', false);
    }
}

function openTabByHashId() {
    if (window.location.hash.indexOf('tab') > -1) {
        const tabContent = document.getElementById(window.location.hash.replace('#', ''));
        if (tabContent) {
            const tab = document.getElementById(tabContent.getAttribute('aria-labelledby'));
            openTab(tab);
        }
    }
}

/**
 * Initialise Tabs with this function
 * Will only run if given selector elements are found in DOM
 */
export function setupTabs() {
    const tabs = document.querySelectorAll(tabSelector);

    if (tabs) {
        tabs.forEach(tab => {
            const tabTrigger = tab.querySelector('[aria-controls]');
            if (tabTrigger) {
                tabTrigger.addEventListener('click', openTab);
            }
        });
    }

    openTabByHashId();

    window.addEventListener('hashchange', openTabByHashId, false);
}

window.addEventListener('keydown', event => {
    const focusedElement = document.activeElement;
    const closestElement = focusedElement.closest(tabSelector);

    if (focusedElement && closestElement) {

        // Open previous tab
        if (event.keyCode === keycode.arrowLeft) {
            const newFocusedElement = closestElement.previousElementSibling;
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }

        // Open next tab
        if (event.keyCode === keycode.arrowRight) {
            const newFocusedElement = closestElement.nextElementSibling;
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }

        // Open first tab
        if (event.keyCode === keycode.home) {
            const newFocusedElement = closestElement.parentNode.querySelector(`${tabSelector}:first-child`);
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                event.preventDefault();
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }

        // Open last tab
        if (event.keyCode === keycode.end) {
            const newFocusedElement = closestElement.parentNode.querySelector(`${tabSelector}:last-child`);
            if (newFocusedElement && newFocusedElement.querySelector('[aria-controls]')) {
                event.preventDefault();
                openTab(newFocusedElement.querySelector('[aria-controls]'));
            }
        }
    }
});
